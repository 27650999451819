<template>
  <div class="table-list" :class="{skeleton: !state.loaded}">
    <div class="title font-md">
      <span>{{ modalParams.title || "목록" }}</span>
    </div>
    <div class="table-responsive" v-if="state.list.length || !state.loaded">
      <table class="table table-hover">
        <thead>
        <tr>
          <th v-for="(h, idx) in modalParams.head" :key="idx">
            <span>{{ h.title }}</span>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(a, idx1) in state.list" :key="idx1">
          <td v-for="(i, idx2) in modalParams.head" :key="idx2">
            <span v-if="i.type === 'mobile'">{{ $lib.getPhoneNumberFormat(a[i.name], true) }}</span>
            <span v-else-if="i.type === 'date'">{{ $lib.getDateFormat(a[i.name], "yyyy-MM-dd") }}</span>
            <span v-else-if="i.type === 'number'">{{ $lib.getNumberFormat(a[i.name]) }}</span>
            <span v-else>{{ a[i.name] }}</span>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <NoData v-else/>
  </div>
</template>

<script>import {defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import store from "@/scripts/store";
import NoData from "@/components/NoData";
import http from "@/scripts/http";

function Component(initialize) {
  this.name = "modalTableList";
  this.initialize = initialize;
}

export default defineComponent({
  components: {NoData},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      store.commit("tuneModal", {component, size: "md",});

      if (modalParams.url) {
        load(modalParams.url);
      } else {
        state.loaded = true;
        state.list = modalParams.list;
      }
    });

    const state = reactive({
      loaded: false,
      list: [],
      args: {
        page: 0,
        size: 50,
      },
      pageable: {
        page: 0,
        size: 0
      },
      total: 0,
    });

    const modalParams = store.getters.modalParams(component);

    const load = (url) => {
      state.loaded = false;

      for (let i = 0; i < 5; i += 1) {
        state.list.push({
          email: "Wait a moment",
          memberName: "Wait",
          mobile: "00000000000",
          createDate: "0000-00-00"
        });
      }

      http.get(url).then(({data}) => {
        state.loaded = true;
        state.list = data.body.content;
        state.pageable = data.body.pageable;
        state.total = data.body.total;
      });
    };

    return {component, state, modalParams};
  }
});
</script>

<style lang="scss" scoped>
.table-list {
  min-height: $px100;
  background: #fff;
  padding: $px25;

  .title {
    margin-bottom: $px17;
  }

  .table-responsive .table {
    overflow: auto;
    text-align: center;

    thead tr {
      background: #f7f7f7;
    }
  }

  &.skeleton {
    .title {
      @include skeleton;
    }

    .table-responsive .table {
      thead tr, tbody tr {
        th, td {
          span {
            @include skeleton;
          }
        }
      }
    }
  }
}
</style>